/* -------------------------------------------------------------------------- */
/*                              Form Validation                               */
/* -------------------------------------------------------------------------- */

const formValidationInit = () => {
  const forms = document.querySelectorAll('.needs-validation');

  forms.forEach(form => {
    form.addEventListener(
      'submit',
      event => {
          form.classList.remove('was-validated');
          let customPass = true;
        if (form.checkValidity()) {
            const customFields = document.querySelectorAll('.custom-validator');
            customFields.forEach((f) => {
                const validatorFunction = f.getAttribute("data-custom-validator");
                switch (validatorFunction) {
                    case "uniquevotername":
                        customPass = isVoterUnique(f);
                }
                if(!customPass) {
                    event.preventDefault();
                    event.stopPropagation();
                    f.classList.add("is-invalid");
                }
            });
            if(customPass) {
                form.classList.add('was-validated');
            }
        } else {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add('was-validated');
        }
      },
      false
    );
  });
};
export default formValidationInit;
